import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.css'
import './assets/css/font.css'
import {deleteRequest, getRequest, postRequest, putRequest} from "./utils/api"
import {downloadRequest} from './utils/download.js'
import * as echarts from 'echarts'
import './assets/css/global.css'
import 'amfe-flexible/index.js'
import SlideVerify from 'vue-monoplasty-slide-verify'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(ElementUI, {size: "small"})
Vue.use(SlideVerify)
Vue.use(VueCookies)

Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.downloadRequest = downloadRequest;

router.beforeEach((to, from, next) => {
    if (window.sessionStorage.getItem("tokenStr")) {
        if (!window.sessionStorage.getItem("role")) {
            // 判断角色信息是否存在
            return getRequest("/role/info").then((resp) => {
                if (resp) {
                    // 存入用户信息
                    store.commit("INIT_ROLE", resp);
                    window.sessionStorage.setItem("role", JSON.stringify(resp));
                    next();
                }
            });
        }
        next();
    } else {
        if (to.path == "/") {
            next();
        } else {
            next("/?redirect=" + to.path);
        }
    }
})

const app = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
app.echarts = echarts
