import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const now = new Date();

const store = new Vuex.Store({
    state: {
        currentRole: JSON.parse(window.sessionStorage.getItem("role")),
        sexs: ['男', '女'],
    },

    mutations: {
        INIT_ROLE(state, role) {
            state.currentRole = role;
        },
    },


    actions: {}

});

export default store;